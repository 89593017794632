 @import '../../assets/styles/github';

 .sec-post {
     margin: 5rem 10%;
     padding: 2rem;
     position: relative;
     display: flex;
     justify-content: space-between;

     .toc-wrap {
         width: 20%;
         margin: 10rem 5% 0 0;
         overflow: hidden;

         &>h2 {
             position: relative;
             bottom: 0;
         }

         .ant-affix {
             top: 8rem !important;

             .ant-anchor-wrapper {
                 background: transparent;
             }
         }
     }

     .post-wrap {
         width: 75%;
     }

     @media screen and(max-width: 768px) {
         margin: 2rem 0 0 0;
         padding: 1rem;

         .toc-wrap {
             display: none;
         }

         .post-wrap {
             width: 100%;
         }
     }
 }


 .article {
     border-radius: 0.5rem;
     background-image: linear-gradient(to bottom, #ece9e8, #fefdfb);
     position: relative;

     .post-header {
         text-align: center;
         position: relative;
         background: transparent;
         z-index: 1;

         &:hover>.poster>.bg {
             height: 100%;
             transform: scale(1.1);
         }

         .poster {
             overflow: hidden;
             position: absolute;
             border-radius: 0.5rem 0.5rem 0 0;
             background-image: linear-gradient(58deg, #e3745d 0%, #f4a16b 47%, #ffbf74 100%);
             ;
             top: 0;
             left: 0;
             width: 100%;
             height: 100%;
             z-index: -1;

             .bg {
                 width: 100%;
                 height: 0;
                 object-fit: cover;
                 transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
             }
         }

         @media screen and(max-width: 736px) {
             .poster>.bg {
                 height: 100%;
             }
         }

         .pub-date {
             position: absolute;
             top: 0;
             left: 50%;
             transform: translate(-50%, -50%);
             font-family: "roboto-mono", "Roboto Mono", monospace, sans-serif;
             color: #FFFFFF;
             text-align: center;
             line-height: 1.5rem;
             background: #5FCCC4;
             padding: 0.1rem 1rem;
             border-radius: 1rem;
             white-space: nowrap;
         }

         .title {
             min-height: 6rem;
             margin: 0;
             padding: 5rem 2rem 0 2rem;
             display: flex;
             flex-direction: column;
             justify-content: center;
             font-size: 4rem;
             font-weight: 300;
             color: #333;
             letter-spacing: .3rem;
             font-family: "roboto-mono", "Roboto Mono", monospace, sans-serif;

             @media screen and(max-width: 736px) {
                 font-size: 2rem;
             }
         }

         .author {
             font-size: 12px;
             line-height: 22px;
             letter-spacing: 3px;
             padding-top: 1rem;
             padding-bottom: 8rem;
             color: white;
             text-transform: uppercase;
             font-family: "roboto-mono", "Roboto Mono", sans-serif;
         }
     }

     .post-content {
         @extend %github;
         position: relative;
         top: -4rem;
         margin: 0 2rem;
         background: white;
         border-radius: .2rem;
         padding: 2rem 2rem 2rem 2rem;
         text-align: justify;
         line-height: 2rem;
         overflow-x: auto;
         z-index: 2;

         img {
             max-width: 100%;
             height: auto;
             object-fit: cover;
         }

         table {
             border-collapse: collapse;
         }

         code {
             display: inline;
             font-size: 85%;
             background: rgba(27, 31, 35, .05);
             padding: .2em .4em;
             border-radius: 3px;
             font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
         }

         pre {
             tab-size: 2;

             code {
                 display: block;
                 overflow-x: scroll;
                 line-height: 1.25;
                 padding: 1rem 1.5rem;
                 border: none;
                 margin: 0 0.25rem;
                 font-size: 0.9rem;
                 font-family: "Courier New", monospace;
                 background: unset;
             }
         }

         @media screen and(max-width: 736px) {
             top: 0;
             margin: 0;
             padding: 1rem;
         }
     }

     .tags {
         list-style: none;
         padding: 1rem 2rem;
         display: flex;
         justify-content: center;
         flex-wrap: wrap;

         &::before {
             content: '';
             width: 2rem;
             background: url(../../assets//images/icon-tag.svg) no-repeat left 25%;
             background-size: 90%;
         }

         .tag {
             display: inline-block;
             color: #B9B2A2;

             &:not(:last-child)::after {
                 content: ',';
                 margin-right: 7px;
                 font-size: 15px;
                 line-height: 20px;
             }

             &::before {
                 content: '#';
                 transition: color .3s ease-in-out;
             }

             &:hover {
                 &::before {
                     color: #E83030;
                 }

                 &>a {
                     color: #E83030;
                 }
             }

             a {
                 color: #B9B2A2;
                 font-size: 15px;
                 line-height: 20px;
                 text-transform: uppercase;
                 text-decoration: none;
                 transition: color .3s ease-in-out;
             }
         }
     }

     .post-author {
         text-align: center;
         padding: 3rem 0 4rem 0;
         margin: 5rem 4rem 0 4rem;
         border-top: 1px solid #E9E9E9;
         position: relative;

         .avatar {
             width: 5rem;
             height: 5rem;
             border-radius: 50%;
             position: absolute;
             top: -2.5rem;
             left: 50%;
             transform: translateX(-50%);
         }

         .author-name {
             font-size: .75rem;
             line-height: 1.375rem;
             letter-spacing: 3px;
             color: #E83030;
             font-weight: normal;
             text-transform: uppercase;
             margin: 1rem 0;
             font-family: "roboto-mono", "Roboto Mono", sans-serif;

         }

         p {
             font-size: .8rem;
             max-width: 23rem;
             margin: 0 auto;
             padding: 0 1rem;
             color: #000;
             line-height: 1.375rem;
         }
     }
 }

 .redirect {
     margin: 2rem 1rem;

     .prev,
     .next {
         padding-top: .5rem;
     }

     span {
         font-weight: bold;
         color: #888;
         text-transform: uppercase;
         letter-spacing: .1rem;
     }

     h3 {
         font-size: 1.5rem;
     }

     .next {
         text-align: right;
     }

     .divider {
         width: 100%;
         height: 2px;
         background: linear-gradient(58deg, #e3745d 0%, #f4a16b 47%, #ffbf74 100%);
     }
 }