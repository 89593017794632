.sec-comment {
    margin-top: 3rem;

    .sec-comment-form {
        background: white;
        padding: 2rem;

        // border-bottom: .25rem solid rgba($color: #e3745d, $alpha: .5);
        &::before {
            position: absolute;
            z-index: -1;
            top: -2px;
            right: -2px;
            bottom: -2px;
            left: -2px;
            background: linear-gradient(58deg, #e3745d 0%, #f4a16b 47%, #ffbf74 100%);
            content: '';
        }
    }
}

.comments {
    margin-top: 3rem;

    h2 {
        border-bottom: 1px solid #ccc;
    }
}

.load-more {
    text-align: center;
    background: white;
}