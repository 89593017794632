.sec-home {
    margin: 0 10%;

    @media screen and (max-width: 768px) {
        margin: 0 5%;
    }
}

.intro-wrap {
    padding: 3rem 0 18rem;
    background-image: linear-gradient(58deg, #e3745d 0%, #f4a16b 47%, #ffbf74 100%);
}

.title,
.description {
    font-weight: normal;
    margin: 0 auto;
    text-align: center;
    opacity: .9;
}

.title {
    font-size: 2.5rem;
    padding: 10px 40px 20px;
}

.description {
    font-size: 1.5rem;
    line-height: 1.8;
    max-width: 50rem;
    padding: 0 3.125rem;
}

.vertical {
    position: absolute;
    z-index: 1;
    left: 48%;
    width: 2px;
    top: -6rem;
    height: 13rem;
    background-image: linear-gradient(58deg, #e3745d 0%, #f4a16b 47%, #ffbf74 100%);

    .rotate {
        font-size: 1.5rem;
        position: absolute;
        bottom: 5rem;
        width: 15rem;
        text-align: right;
        transform: translateX(-42%) translateY(0%) rotate(90deg);
    }
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.featured-wrap {
    margin: -8rem 0 0 0;
    position: relative;

    .featured-card {
        margin: 0 3%;
        background-image: linear-gradient(to top, #ece9e6, #fff);
        transition: transform 0.65s ease-in-out 0s, opacity 0.65s ease-in-out 0s;
        display: flex;
    }

    .bg-bottom {
        z-index: -1;
        background-image: linear-gradient(58deg, #e3745d 0%, #f4a16b 47%, #ffbf74 100%);
        width: 100%;
        height: 25rem;
        position: relative;
        margin-top: -10rem;
    }
}

.mansory-wrap {
    position: relative;
    padding-top: 2rem;

    .post:first-child {
        //margin-top: -5rem;
    }

    .mansory {
        display: flex;
        flex-wrap: wrap;
    }
}

.page-wrap {
    text-align: center;
}