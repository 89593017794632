.sec-auth {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    margin-top: -8rem;
}

.auth-wrap {
    position: relative;
    display: flex;
    top: -180px;
    background-image: linear-gradient(58deg, #e3745d 0%, #f4a16b 47%, #ffbf74 100%);



    @media screen and(max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        padding: 4rem;

        .auth-intro {
            margin: 0 0 3rem 0;
        }

        .auth {
            position: unset;
            top: 0;
            transform: unset;

            &.turnaround {
                transform: rotateY(180deg);
            }
        }
    }

    @media screen and(max-width: 512px) {
        padding: .5rem;

        .auth-intro {
            display: none;
        }
    }
}

.auth-intro {
    width: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;

    @keyframes typing {
        from {
            width: 0
        }
    }

    @keyframes caret {
        50% {
            border-right-color: transparent;
        }
    }

    h1 {
        font-size: 4rem;
        text-transform: uppercase;
        letter-spacing: .5rem;
        padding: 0 1rem;
        margin: 1rem 0;

        &::first-letter {
            font-size: 6rem;
        }

        a {
            text-decoration: none;
            color: white;
            font-family: cursive;

        }
    }

    h2 {
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        color: white;
        font-family: monospace;
        font-size: 2rem;
        width: 37.8125rem;
        letter-spacing: .1rem;
        border-right: .05em solid;
        animation: typing 6s steps(29) 1000ms,
            caret 1s steps(1) infinite;
    }

    @media screen and(max-width: 1216px) {
        margin: 1rem;

        h1 {
            font-size: 3rem;
        }

        h2 {
            font-size: 1.25rem;
        }
    }

    @media screen and(max-width: 736px) {
        h1 {
            font-size: 2.5rem;
            letter-spacing: .25rem;

            &::first-letter {
                font-size: 3rem;
            }
        }

        h2 {
            display: none;
        }
    }
}

.auth {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 25rem;
    float: right;
    display: flex;
    border-radius: .5rem;
    transition: transform 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 5px 6px 26px -3px rgba(0, 0, 0, 0.22);
    margin: auto 5rem;
    background: white;
    margin: auto 5rem;


    &.turnaround {
        transform: rotateY(180deg) translateY(-50%);

        .signup {
            z-index: 999;
            visibility: visible;
        }

        .login {
            visibility: hidden;
        }
    }

    &>div {
        width: 100%;
        border-radius: .5rem;
        text-align: center;
        transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        padding: 3rem 0 0 0;
        background: white;
    }

    .signup {
        float: left;
        transform: rotateY(180deg);
        visibility: hidden;
        z-index: -999;
    }

    .login {
        position: relative;
        margin-left: -100%;
        visibility: visible;
    }

    form {
        padding: 2rem 2rem;

        .form-button {
            width: 100%;
            text-transform: uppercase;
        }

        .login {
            position: relative;
            margin-left: -100%;
            visibility: visible;
        }

        .form-actions {
            display: flex;
            justify-content: space-between;
        }
    }

    .logo {
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        background-image: linear-gradient(100deg, #e3745d 0%, #f4a16b 47%, #ffbf74 100%);
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 5px 6px 26px -3px rgba(0, 0, 0, 0.22);
    }

    @media screen and (max-width: 500px) {
        float: none;
        width: 90%;
        margin: 4rem auto;
    }
}