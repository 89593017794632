.comment {
    background: white;
    padding: 0 1rem;
    &:not(:last-of-type)::after{
        content: '';
        display: block;
        width: 100%;
       border-bottom: 1px dashed #eee;
    }

    .comment-content {
        blockquote {
            color: rgba(#666 ,.8);
            padding: 1rem;
            border-top-right-radius: .25rem;
            border-bottom-right-radius: .25rem;
            border-left: .5rem solid rgba(#ffbf74, .5);
        }
    }
}

.user-info {
    display: flex;
    &>div{
        flex: 1;
    }
}

.reply-form {
    margin: -1rem 0 1rem 2.5rem;
    padding: .5rem;
    border-radius: .25rem;
    background: #fefdf0;//rgba(#ffbf74, .3);
    position: relative;

    &::before {
        content: '';
        border: .5rem solid transparent;
        border-bottom-color: #fefdf0;
        position: absolute;
        top: -1rem;
        left: 1rem;
    }
}
