.sec-editor {
    margin: 0 5%;
}

.editor-form {
    display: flex;
    flex-direction: row;
    height: 75vh;
    overflow: hidden;
    border: 1px solid #ddd;

    &>div:not(:last-of-type) {
        border-right: 1px solid #ddd;

        @media screen and (max-width: 512px) {
            border: none;
        }
    }

    .meta {
        background: white;
        width: 16rem;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: scroll;

        .fields {
            flex: 1;
            margin: 1rem;
            display: flex;
            flex-direction: column;
        }

        .form-actions {
            display: flex;

            button {
                flex: 1;
                color: white;
                background-image: linear-gradient(58deg, #e3745d 0%, #f4a16b 47%, #ffbf74 100%);
                border: none;
                border-radius: 0 !important;
            }

            @media screen and (max-width: 512px) {
                background: #5FCCC4;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
        }

        @media screen and (max-width: 512px) {
            max-height: 60%;
            width: 100%;
            overflow: hidden scroll;
            padding-top: 3rem;
        }
    }

    @media screen and (max-width: 512px) {
        flex-direction: column;
        height: 150vh;
    }
}

.raw {
    flex: 1;

    .raw-editor {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .react-mde {
        height: 100%;
        border: none !important;

        .mde-tabs {
            display: none;
        }
    }

    textarea {
        flex: 1;
        background: white;
    }

    .grip {
        display: none;
    }

    @media screen and (max-width: 412px) {
        border-top: 1px solid #ddd;
    }
}

.preview {
    flex: 1;
    background: white;
    overflow: scroll;
    padding: 1rem;

    img {
        width: 100%;
        height: auto;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
}