.post {
    width: 40%;
    margin: 0 5% 5rem 5%;
    text-align: center;

    .cover {
        display: block;
        height: fit-content;
        width: 100%;
        overflow: hidden;
        background: white;
        box-shadow: 5px 6px 26px -3px rgba(0, 0, 0, .22);
        transition: transform .25s ease-in-out;

        &:hover {
            transform: scale(.96);
        }

        img {
            width: 100%;
            height: auto;
            max-height: 25rem;
            object-fit: cover;
            transition: transform .25s ease-in-out;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .title {
        padding: 0;
        margin: 1rem;
        //text-align: left;
    }

    .meta {
        font-size: .8rem;
        color: #ccc;
        font-style: italic;
    }

    .more-button {
        font-size: 1.2rem;
        font-weight: bold;
    }

    @media screen and (max-width: 768px) {
        width: 90%;
        text-align: center;

        .title {
            text-align: center;
            font-size: 1.5rem;
        }
    }
}

.featured {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 5%;
    text-align: left;

    .title {
        text-align: left;
    }

    .cover {
        margin: 0 4rem 3rem 0;
        height: fit-content;
        flex: 1;
        margin-top: -2rem;
        overflow: hidden;
        background: white;
        box-shadow: 5px 6px 26px -3px rgba(0, 0, 0, 0.22);

        &:hover {
            transform: unset;
        }

        img {
            width: 100%;
            height: 27rem;
            object-fit: cover;
            transition: transform .25s ease-in-out;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .content {
        flex: 1;
        margin: 1rem;

        h2 {
            font-size: 2.2rem;
            margin: 2.5rem 0;
        }

        .more-button {
            display: inline-block;
            font-size: 1rem;
            font-weight: bold;
            margin: 1rem;
            height: 3.5rem;
            padding: 0 2rem;
            line-height: 3.5rem;
            letter-spacing: 1px;
            border: 2px solid;
            border-radius: 1.75rem;
            outline: none;
            text-decoration: none;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        text-align: center;

        .title {
            text-align: center;
        }
    }
}