.header-wrap {
    margin: 0 10%;
    padding: 3rem 2rem;
    position: relative;

    &::after {
        content: '';
        display: block;
        clear: both;
    }

    .left {
        float: left;
    }

    .right {
        float: right;
    }

    @media screen and (max-width: 768px) {
        padding: 3rem 0;
        margin: 0 5%;
    }
}

.nav-wrap {
    font-size: 0.8rem;
    font-weight: bold;
    height: 4rem;
    padding: 0 30px 0 0;
    letter-spacing: .9px;

    .nav-wrap--label {
        display: none;

        .hamburger {
            position: relative;
            display: block;
            float: right;
            width: 2.5rem;
            height: 3rem;

            .line {
                display: inline-block;
                position: absolute;
                height: 6px;
                width: 100%;
                background-image: linear-gradient(58deg, #e3745d 0%, #f4a16b 47%, #ffbf74 100%);
                border-radius: 10px;
                transition: all cubic-bezier(0.25, 0.1, 0.28, 1.54) 0.32s;

                @for $i from 0 to 3 {
                    &:nth-of-type(#{$i + 1}) {
                        top: #{(15 + 30 * $i) + '%'}
                    }
                }
            }

            &.close .line {
                top: 45% !important;

                &:first-of-type {
                    transform: rotate(45deg);
                }

                &:nth-of-type(2),
                &:nth-of-type(3) {
                    transform: rotate(-45deg);
                    top: 45% !important;
                }
            }
        }

        @media screen and (max-width: 768px) {
            display: inline-block;
            cursor: pointer;
        }
    }

    input[type=checkbox] {
        display: none;

        &:checked+.nav-wrap--list {
            display: block;
            position: absolute;
            right: 5%;
            flex-direction: column;
            height: fit-content;
            width: fit-content;
            box-shadow: 0 0 10px rgba(0, 0, 0, .3);
            z-index: 10;
            background: white;
            transition: width 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

            li {
                padding: 1rem;
                z-index: 100000000;
            }
        }
    }

    .nav-wrap--list {
        height: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;

        .nav-wrap--list-item {
            margin: 0 1rem;
        }

        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}

.logo-image {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    background:linear-gradient(58deg,  #161b3d 0, #3b2666 100%) ;
    color: white !important;
    text-align: center;
    line-height: 4rem;
    font-weight: 900;
    font-size: 3rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.main-wrap {
    flex: 1;
    overflow: hidden;
}

.footer-wrap {
    margin: 0 10%;
    margin: 0 auto 1rem;
    padding: 2.5rem 0 2rem;
    text-align: center;

    .footer-copyright {
        font-size: .75rem;
        line-height: 1.8;
        max-width: 450px;
        margin: 1rem auto 1.625rem;
        letter-spacing: 1px;
    }

    @media screen and (max-width: 768px) {
        margin: 5%;
    }
}