.sec-tags {
    margin: 0 10%;

    @media screen and (max-width: 768px) {
        margin: 0 5%;
    }
}

.tags-wrap {
    background-image: linear-gradient(58deg, #e3745d 0%, #f4a16b 47%, #ffbf74 100%);
    padding: 3rem 3rem 5rem 3rem;

    .ant-tag {
        &:hover{
            cursor: pointer;
        }
    }

    .ant-tag-checkable-checked {
        background: #484795
    }

    @media screen and (max-width: 512px) {
        padding: 2rem 1rem 3rem 1rem;
    }
}

.results-wrap {
    margin-top: 5rem;

    .masonry {
        display: flex;
        flex-wrap: wrap;
    }
}